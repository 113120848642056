import React, { useEffect, useMemo, useState } from 'react';
import { H5, P } from '../../../AbstractElements';
import { Card, CardBody, CardHeader, Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import userColumns from '../../../assets/data/userColumns';
import ReactTable from 'react-data-table-component';
import { useAppData } from '../../../context/AppDataContext';
import FilterComponent from '../../Common/Component/FilterComponent';
import UserCard from './UserCard';
export default function UsersTable() {
    const [loading, setLoading] = useState(false);
    const {users} = useAppData();
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [filterBy,setFilterBy] = useState('name');
    const [selectedUser, setSelectedUser] = useState(null);
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

    const enabledUserCount = () => {
      if (!users) return 0; // Return 0 if data is null

      // Filter the users based on the 'enabled' field
      const enabledUsers = users.filter(user => user.enabled === true);
      return enabledUsers.length; // Return the count of enabled users
  };
    
  const conditionalRowStyles = [
    {
      when: row => true,
      style: {
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },

  ];
  const handleRowClick = (row) => {
    setSelectedUser(row);
    toggle();
};

    const filteredUsers = useMemo(() => {
        if (!users) return [];
        if(filterBy == "name")return users.filter(user => user.name && user.name.toLowerCase().includes(filterText.toLowerCase()));
        if(filterBy == "email")return users.filter(user => user.email && user.email.toLowerCase().includes(filterText.toLowerCase()));
        if(filterBy == "telephoneNumber")return users.filter(user => user.telephoneNumber && user.telephoneNumber.toLowerCase().includes(filterText.toLowerCase()));
        if(filterBy == "IDNO")return users.filter(user => user.idno && user.idno.toLowerCase().includes(filterText.toLowerCase()));
        if(filterBy == '') return users;
    }, [users, filterText]);


    const handleClearFilter = () => {
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
        }
    };
    

    const subHeaderComponentMemo = useMemo(() => {
        return (
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                 <Input
                    className='w-auto'
                    type="select"
                    name="filterBy"
                    value={filterBy}
                    onChange={(e) =>setFilterBy(e.target.value)}
                  >
                <option value="name">Nume</option>
                <option value="email">Email</option>
                <option value="telephoneNumber">Telefon</option>
                <option value="IDNO">IDNO</option>

                  </Input>
            <FilterComponent 
                onFilter={e => setFilterText(e.target.value)} 
                onClear={handleClearFilter} 
                filterText={filterText} 
                filterBy = {filterBy}
            />
            </div>
        );
    }, [filterText, resetPaginationToggle,filterBy]);
  return (
        <>
        <Card>
            <CardHeader>
                <H5>Utilizatori</H5>
                <P>Utilizatori reali: {enabledUserCount()}</P>
            </CardHeader>
            <CardBody>
                <ReactTable
                    columns={userColumns}
                    data={filteredUsers}
                    noHeader
                    pagination
                    highlightOnHover
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    onRowClicked={handleRowClick}
                    conditionalRowStyles={conditionalRowStyles}
                />
            </CardBody>
        </Card>
        {selectedUser && (
                <Modal isOpen={modal} toggle={toggle} size="xl">
                    <ModalHeader toggle={toggle}>Detalii utilizator</ModalHeader>
                    <ModalBody>
                        <UserCard user={selectedUser} toggleParentModal={toggle} />
                    </ModalBody>
                </Modal>
            )}
        </>
  )
}
