import React from 'react'
import { Button,Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import userColumns from '../../../assets/data/userColumns';
import ReactTable from 'react-data-table-component';
export default function OnlineUsersModal({modal,toggle,users, ...args}) {

  return (
    <div>
        <Modal isOpen={modal} toggle={toggle} size="lg" {...args}>
          <ModalHeader toggle={toggle}></ModalHeader>
          <ModalBody>
          <ReactTable
                    columns={userColumns}
                    data={users}
                    noHeader
                    pagination
                    highlightOnHover
                    subHeader
                    // onRowClicked={handleRowClick}
                    // conditionalRowStyles= {conditionalRowStyles}
                />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle} type="button">
              Ascunde
            </Button>
          </ModalFooter>
        </Modal>
      </div>
  )
}

