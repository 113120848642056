import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import { Button, Form, FormGroup, Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ReactTable from 'react-data-table-component';
import userService from '../../../Services/UserService';
import transactionColumns from '../../../assets/data/transactionColumns';
export default function TransactionsModal({modal,toggle,userId, ...args}) {
    const [transactions, setTransactions] = useState(null);
    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                const response = await userService.getUserTransactions(userId);
                setTransactions(response);
            } catch (error) {
                toast.error('Eroare la afisare tranzactii.', {
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        };
        fetchTransactions();
    }, [])
    
    const conditionalRowStyles = [
        {
          when: row => true,
          style: {
            '&:hover': {
              cursor: 'pointer',
            },
          },
        },
    
      ];
  return (
    <div>
        <Modal isOpen={modal} toggle={toggle} size="lg" {...args}>
          <ModalHeader toggle={toggle}></ModalHeader>
          <ModalBody>
          <ReactTable
                    columns={transactionColumns}
                    data={transactions}
                    noHeader
                    pagination
                    highlightOnHover
                    subHeader
                    conditionalRowStyles= {conditionalRowStyles}
                />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle} type="button">
              Ascunde
            </Button>
          </ModalFooter>
        </Modal>
      </div>
  )
}
