export const MENUITEMS = [
  {
    menutitle: 'General',
    menucontent: 'Dashboards,Widgets',
    Items: [
      {
        title: 'Pages',
        icon: 'general',
        type: 'sub',
        children: [
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/pages/general`,
            title: 'General',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/pages/users`,
            title: 'Utilizatori',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/pages/subscriptions`,
            title: 'Abonamente',
            type: 'link',
          },
                    {
            active: false,
            path: `${process.env.PUBLIC_URL}/pages/transactions`,
            title: 'Tranzactii',
            type: 'link',
          },
          {
            active: false,
            path: `${process.env.PUBLIC_URL}/pages/notify`,
            title: 'Notificare',
            type: 'link',
          },
        ],
      },
      {
        title: 'Support Ticket',
        icon: 'support-tickets',
        type: 'sub',
        children: [
          {
            active: false,
            path: `http://support.pixelstrap.com/help-center`,
            title: 'Rise Ticket',
            type: 'link',
          },
        ],
      },
    ],
  },
];
