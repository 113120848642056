import React, {useState, useEffect, useContext} from 'react'
import userService from '../Services/UserService';

const AuthContext = React.createContext()

export function useAuth(){
    return useContext(AuthContext);
}

export function AuthProvider(props){
    const [authUser, setAuthUser] = useState(null)
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [loading, setLoading] = useState(true); 
    const checkAuthentication = async () => {
        try {
            setLoading(true);
            const userData = await userService.getCurrentUser();
            setAuthUser(userData.userDTO);
            localStorage.setItem('Name', userData.name);
            setIsLoggedIn(true);
        } catch (error) {
            setIsLoggedIn(false);
            setAuthUser(null);
        } finally {
            setLoading(false);  
        }
    };
    useEffect(() => {
        checkAuthentication();
    }, []);
    
    if (loading) {
        return <div></div>;  
    }

    const value = {
        authUser,
        setAuthUser,
        isLoggedIn,
        setIsLoggedIn,
        checkAuthentication,
        loading,
        setLoading
    }

    return (
        <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
    )
}