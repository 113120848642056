import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import { Button, Form, FormGroup, Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ReactTable from 'react-data-table-component';
import userService from '../../../Services/UserService';
import calculationColumns from '../../../assets/data/calculationColumns';
export default function CalculationsModal({modal,toggle,userId, ...args}) {
    const [calculations, setCalculations] = useState(null);
    useEffect(() => {
        const fetchCalculations = async () => {
            try {
                const response = await userService.getUserCalculations(userId);
                setCalculations(response);
            } catch (error) {
                toast.error('Eroare la afisare calcule.', {
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        };
        fetchCalculations();
    }, [])
    
    const handleRowClick = (row) => {
        const link = "https://calc.prolex.md/rezultat?calcId=" + row.calcId;
        window.open(link, '_blank').focus();
    };
    const conditionalRowStyles = [
        {
          when: row => true,
          style: {
            '&:hover': {
              cursor: 'pointer',
            },
          },
        },
    
      ];
  return (
    <div>
        <Modal isOpen={modal} toggle={toggle} size="lg" {...args}>
          <ModalHeader toggle={toggle}></ModalHeader>
          <ModalBody>
          <ReactTable
                    columns={calculationColumns}
                    data={calculations}
                    noHeader
                    pagination
                    highlightOnHover
                    subHeader
                    onRowClicked={handleRowClick}
                    conditionalRowStyles= {conditionalRowStyles}
                />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle} type="button">
              Ascunde
            </Button>
          </ModalFooter>
        </Modal>
      </div>
  )
}
