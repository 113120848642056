import React from 'react'
import { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import userService from '../../../Services/UserService';

export default function SubscriptionModal({modal,toggle,userId, ...args}) {
    const [planId,setPlanId] = useState("lun20");
    const [maxActiveSessions, setMaxActiveSessions] = useState(3);
    const [firstBillingDay,setFirstBillingDay] = useState(null);
    const [lastBillingDay,setLastBillingDay] = useState(null);
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if(planId == null) {
              toast.error('Abonament nu a fost selectat!', {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              return;
            }
            const response = userService.setSubscription(userId,planId,maxActiveSessions, firstBillingDay != null ? `${firstBillingDay}T00:00:00` : null, lastBillingDay != null ? `${lastBillingDay}T00:00:00` : null);
            toast.success('Abonament activat cu succes!', {
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } catch (error) {
            toast.error('Activare abonamentului a esuat!', {
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }finally{
            toggle();
          }
    }
  return (
    <div>
        <Modal isOpen={modal} toggle={toggle} {...args}>
          <ModalHeader toggle={toggle}></ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit}>
            <FormGroup>
                        <Label>Abonament:</Label>
                        <Input
                        type="select"
                        name="plan"
                        id="plan"
                        value={planId}
                        onChange={(e) => {setPlanId(e.target.value)}}
                    >
                        <option value="lun20">Lunar</option>
                        <option value="sem80">Semestrial</option>
                        <option value="anu150">Anual</option>
                    </Input>
                </FormGroup>
                <FormGroup>
                        <Label>Maxim sesiuni active:</Label>
                        <Input type='numeric' value={maxActiveSessions} onChange={(e)=>setMaxActiveSessions(e.target.value) } />
                </FormGroup>
                <FormGroup>
                        <Label>Data inceput abonament:</Label>
                        <Input type='date' value={firstBillingDay} onChange={(e)=>setFirstBillingDay(e.target.value) } />
                </FormGroup>
                <FormGroup>
                        <Label>Data sfarsit abonament:</Label>
                        <Input type='date' value={lastBillingDay} onChange={(e)=>setLastBillingDay(e.target.value) } />
                </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleSubmit}>
            Activează
            </Button>{' '}
            <Button color="secondary" onClick={toggle} type="button">
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
  )
}