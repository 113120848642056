import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { Button, Form, FormGroup, Input, InputGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import userService from '../../../Services/UserService';
export default function SuspendUserModal({modal,toggle,userId, ...args}) {
    const [reason, setReason] = useState(null);
    const [endDate,setEndDate] = useState(null);
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await userService.suspendUser(userId,reason,endDate);
            toast.success('Utilizator suspendat cu succes!', {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            toggle();
        } catch (error) {
            toast.error('Eroare la suspendare utilizator.', {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
        }
    }
  return (
    <div>
        <Modal isOpen={modal} toggle={toggle} {...args}>
          <ModalHeader toggle={toggle}></ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                        <Label>Suspenda pana </Label>
                        <Input type='date' value={endDate} onChange={(e)=>setEndDate(e.target.value) } />
                        <Label>Motiv</Label>
                        <textarea
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                            placeholder="Motivul suspendarii"
                            style={{width: "100%", height: "10vh"}}
                            />
                </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleSubmit}>
            Suspenda
            </Button>{' '}
            <Button color="secondary" onClick={toggle} type="button">
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
  )
}
