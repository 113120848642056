import React from 'react'
import { Button, Col, Input, Row } from 'reactstrap';
import styled from 'styled-components';

export default function FilterComponent({ filterText, onFilter, onClear,filterBy }) {

const ClearButton = styled(Button)`
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	height: 38px;
	width: 32px;
	text-align: center;
	display: block;
	align-items: center;
	justify-content: center;
`;

  return (
    <>
    <Row>
        <Col>
        <div style={{ display: 'flex' }}>
        <Input
        id="search"
        type="text"
        placeholder={filterBy == "name" ? "cautare dupa nume" : filterBy == "email" ? "cautare dupa email" : filterBy == "telephoneNumber" ? "cautare dupa telefon" : filterBy ==  "IDNO" ? "cautare dupa idno" : ''}
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
        style={{ borderRadius: '5px 0 0 5px' }}
    />
    <ClearButton type="button" onClick={onClear}>
        X
    </ClearButton>
        </div>
        </Col>
    </Row>
</>
  )
}
