import React, { useState, useEffect, createContext, useContext } from 'react';
import userService from '../Services/UserService';
import { useAuth } from './AuthContext';

// Create the context
const AppDataContext = createContext();

// Create a custom hook to consume the context
export const useAppData = () => useContext(AppDataContext);

// Create the context provider
export const AppDataProvider = ({ children }) => {
    const {authUser} = useAuth();
    const [transactions, setTransactions] = useState([]);
    const [users, setUsers] = useState([]);
    const [totalCalculations, setTotalCalculations] = useState(null);
    const [subscriptions, setSubscriptions] = useState([]);
    const refetchData = async () => {
      try {
        const transactionData = await userService.getAllTransactions();
        setTransactions(transactionData);
        const userData = await userService.getAllUsers();
        setUsers(userData);
        const subscriptionData = await userService.getAllSubscriptions();
        setSubscriptions(subscriptionData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    useEffect(() => {
      const fetchTransactions = async () => {
        try {
          const transactionData = await userService.getAllTransactions();
          setTransactions(transactionData);
        } catch (error) {
          console.error('Error fetching transactions:', error);
        }
      };
  
      const fetchUsers = async () => {
        try {
          const userData = await userService.getAllUsers();
          setUsers(userData);
        } catch (error) {
          console.error('Error fetching users:', error);
        }
      };
      const fetchTotalCalculations = async () => {
        try {
          const response = await userService.getTotalCalculations();
          setTotalCalculations(response.data);
        } catch (error) {
          console.error('Error fetching users:', error);
        }
      };
      const fetchSubscriptions = async () => {
        try {
          const subscriptionData = await userService.getAllSubscriptions();
          setSubscriptions(subscriptionData);
        } catch (error) {
          console.error('Error fetching subscriptions:', error);
        }
      };
     if(authUser){
      fetchSubscriptions();
      fetchTransactions();
      fetchUsers();
      fetchTotalCalculations();
     }
    }, [authUser]);

  return (
    <AppDataContext.Provider value={{ transactions, users, subscriptions,totalCalculations,refetchData }}>
      {children}
    </AppDataContext.Provider>
  );
};
