import moment from "moment";

const calculationColumns = [
  {
    name: 'ID',
    selector: row => row.id,
    sortable: true,
    center: false,

  },
  {
    name: 'Nume',
    selector: row => row.name,
    sortable: true,
    center: false,
    width:"20%",
  },
  {
    name: 'Autor',
    selector: row => row.creatorEmail,
    sortable: true,
    center: false,
  },
  {
    name: 'Data Creării',
    selector: row => moment(row.createdDate).format('DD.MM.YYYY'),
    sortable: true,
    sortFunction: (a, b) => new Date(a.createdDate) - new Date(b.createdDate),
    center: false,
  },
] 
export default calculationColumns;
