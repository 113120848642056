import { marked } from 'marked';
import React, { Fragment, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import SimpleMdeReact from 'react-simplemde-editor';
import { toast } from 'react-toastify';
import { Button, Container, Input, ListGroup, ListGroupItem, FormGroup, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import TurndownService from 'turndown';
import { Breadcrumbs } from '../../../AbstractElements';
import { useAppData } from '../../../context/AppDataContext';
import userService from '../../../Services/UserService';

export default function EmailPage() {
    const [templates, setTemplates] = useState([]);
    const [selectedTemplateId, setSelectedTemplateId] = useState('');
    const [templateContent, setTemplateContent] = useState('');
    const [newTemplateContent, setNewTemplateContent] = useState('');
    const [htmlContent, setHtmlContent] = useState('');
    const [userIds, setUserIds] = useState([]);
    const [subject, setSubject] = useState('');
    const [userRange, setUserRange] = useState('all'); // 'all' or 'specific'
    const [extractedPlaceholders, setExtractedPlaceholders] = useState([]);
    const [templateName, setTemplateName] = useState('');
    const [modal, setModal] = useState(false);
    const { users } = useAppData();
    const [searchParams, setSearchParams] = useSearchParams();
    
    const PLACEHOLDERS = {
        "id": "Identificatorul utilizatorului",
        "name": "Numele utilizatorului",
        "email": "Adresa de email a utilizatorului",
        "phone": "Numărul de telefon al utilizatorului",
        "role": "Rolul utilizatorului",
        "tokens": "Tokenurile utilizatorului",
        "creationDate": "Data creării contului",
        "lastLoginTime": "Ultima dată de conectare",
        "personType": "Tipul de persoană",
        "idno": "IDNO al utilizatorului",
        "savedCardNumber": "Numărul cardului salvat",
        "maxActiveSessions": "Numărul maxim de sesiuni active",
        "maxSubUsers": "Numărul maxim de sub-utilizatori",
        "suspensionReason": "Motivul suspendării",
        "suspensionStartDate": "Data începerii suspendării",
        "suspensionEndDate": "Data încheierii suspendării",
        "subscriptionPlan": "Planul de abonament (lunar/semestrial/anual)",
        "subscriptionPaymentMethod": "Metoda de plată a abonamentului",
        "subscriptionStatus": "Starea abonamentului",
        "subscriptionStartDate": "Data începerii abonamentului",
        "subscriptionEndDate": "Data încheierii abonamentului",
        "subscriptionNextBillingDate": "Data următoarei facturări a abonamentului",
        "subcriptionPrice": "Prețul abonamentului"
    };

    const handleChange = (value) => {
        setNewTemplateContent(value);
        const html = convertToHTML(value);
        setHtmlContent(html);
        extractPlaceholders(value);
    };  

    const convertToHTML = (markdown) => {
        return marked(markdown);
    };
    const convertToMarkdown = (html) => {
        const turndownService = new TurndownService();
        return turndownService.turndown(html);
    };

    const markdownContent = convertToMarkdown(htmlContent);

    const extractPlaceholders = (content) => {
        const regex = /{{(.*?)}}/g;  // Matches {{placeholder}} format
        const matches = [...content.matchAll(regex)];
        const placeholders = matches.map(match => match[1]);
        setExtractedPlaceholders(placeholders);
    };
    useEffect(() => {
        const userIdFromUrl = searchParams.get("userId");
        if(userIdFromUrl != null){
            setUserRange('specific');
            setUserIds([userIdFromUrl]);
        }


        userService.getAllTemplates()
            .then(response => {
                setTemplates(response);
            })
            .catch(error => {
                console.error('Error fetching templates:', error);
            });
    }, []);


    const handleTemplateSelect = (e) => {
        if(e.target.value == 999){
            setTemplateName('');
            setSubject('');
            handleChange('');
        }
        const templateId = e.target.value;
        setSelectedTemplateId(templateId);
    
        // Find the selected template in the templates array
        const selectedTemplate = templates.find(template => template.id == templateId);
        if (selectedTemplate) {
            setTemplateName(selectedTemplate.name);
            setSubject(selectedTemplate.subject);
            handleChange(selectedTemplate.body);
        }
    };  
    console.log(selectedTemplateId);

    const handleSaveTemplate = () => {
        if (!templateName.trim()) {
            toast.error('Numele template-ului este obligatoriu!', {
                autoClose: 3000,
            });
            return;
        }
        if (!subject.trim() || !newTemplateContent.trim()) {
            toast.error('Subiectul și conținutul sunt obligatorii!', {
                autoClose: 3000,
            });
            return;
        }
    
        const newTemplate = {
            name: templateName,
            subject,
            body: newTemplateContent,
        };
    
        userService.saveOrUpdateTemplate(newTemplate)
            .then(response => {
                toast.success('Template salvat cu succes!', {
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                // Reset fields after saving
                setTemplateName('');
                setSubject('');
                setNewTemplateContent('');
            })
            .catch(error => {
                console.error('Eroare la salvarea template-ului:', error);
                toast.error('Salvarea template-ului a eșuat!', {
                    autoClose: 3000,
                });
            });
            setModal(!modal);
    };

    const handleUserRangeChange = (e) => {
        setUserRange(e.target.value);
        if (e.target.value === 'specific') {
            setUserIds([]); // Reset userIds when selecting specific users
        }else if(e.target.value === 'all'){
            setUserIds([]);
        }
    };

    const handleUserSelection = (e) => {
        const selectedUserIds = Array.from(e.target.selectedOptions, option => option.value);
        setUserIds(selectedUserIds);
    };

    const notifyUser = async () => {
        try {
            const placeholdersList = Object.keys(PLACEHOLDERS).map((key) => `${key}`);

            const response = await userService.notifyUser(userIds, subject, htmlContent, selectedTemplateId, placeholdersList,userRange == 'all');
            toast.success('Utilizatori notificati cu succes!', {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (error) {
            toast.error('Notificarea utilizatorilor a esuat!', {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };
    return (
        <Fragment>
            <Breadcrumbs mainTitle='Notificare' parent='Pages' title='Notificare' />
            <Container fluid={true}>
                {/* Subject Input */}
                <FormGroup>
                    <Label for="subject">Titlu email</Label>
                    <Input
                        type="text"
                        id="subject"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        placeholder="Introdu titlu"
                    />
                </FormGroup>

                {/* User Range Selection */}
                <FormGroup>
                    <Label for="userRange">Select User Range</Label>
                    <Input
                        type="select"
                        name="userRange"
                        id="userRange"
                        value={userRange}
                        onChange={handleUserRangeChange}
                    >
                        <option value="all">Toti utilizatorii</option>
                        <option value="specific">Utilizatori selectati</option>
                    </Input>
                </FormGroup>

                {/* User selection for specific users */}
                {userRange === 'specific' && (
                    <FormGroup>
                        <Label for="userIds">Selectati utilizatorii</Label>
                        <Input
                            type="select"
                            name="userIds"
                            id="userIds"
                            multiple
                            value={userIds}
                            onChange={handleUserSelection}
                        >
                            {users.map(user => (
                                <option key={user.id} value={user.id}>
                                    {user.name} ({user.email})
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                )}
                  {/* Dropdown for selecting a template */}
               <FormGroup>
                <Label for="personType">Template</Label>
                <Input
                    className='w-auto'
                    type="select"
                    name="personType"
                    id="personType"
                    value={selectedTemplateId}
                    onChange={handleTemplateSelect}
                >
                    <option key = {999} value = {999}>

                    </option>
                    {templates.map(template => (
                        <option key={template.id} value={template.id}>
                            {template.name}
                        </option>
                    ))}
                </Input>
               </FormGroup>


                {/* SimpleMDE editor for editing template */}
                <SimpleMdeReact
                    value={newTemplateContent}
                    onChange={handleChange}
                />

                
                <Button color='primary' onClick={() => {
                            if (!subject.trim() || !newTemplateContent.trim()) {
                                toast.error('Subiectul și conținutul sunt obligatorii!', {
                                    autoClose: 3000,
                                });
                                return;
                            }
                            setModal(!modal);
                    }} className="mt-3">Salveaza Template</Button>

                
                <Button color='success' onClick={notifyUser} className="mt-3">Notifica utilizatori</Button>

                <h4 className="mt-4">Placeholders disponibili:</h4>
                <ListGroup>
                    {Object.keys(PLACEHOLDERS).map((placeholderKey) => (
                        <ListGroupItem key={placeholderKey}>
                            <strong>{`{{${placeholderKey}}}`}</strong>: {PLACEHOLDERS[placeholderKey]}
                        </ListGroupItem>
                    ))}
                </ListGroup>
            </Container>
            <Modal isOpen={modal} toggle={() => {setModal(!modal)}}>
          <ModalHeader toggle={() => {setModal(!modal)}}></ModalHeader>
          <ModalBody>
          <FormGroup>
            <Label for="templateName">Nume Template</Label>
            <Input
                type="text"
                id="templateName"
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
                placeholder="Introduceți numele template-ului"
            />
        </FormGroup>
          </ModalBody>
          <ModalFooter>
          <Button color='primary' onClick={handleSaveTemplate}>Salveaza</Button>
            <Button color="secondary" onClick={() => {setModal(!modal)}} type="button">
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        </Fragment>
    );
}
