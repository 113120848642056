import React, { Fragment, useEffect, useState } from 'react';
import Charts from 'react-apexcharts';
import { Navigation } from 'react-feather';
import { Card, CardBody, CardHeader, Col, Row, Input, Button } from 'reactstrap';
import { H5 } from '../../../AbstractElements';
import ConfigDB from '../../../Config/ThemeConfig';
import { useAppData } from '../../../context/AppDataContext';

const SalesChart = () => {
    const { transactions } = useAppData();

    const [salesData, setSalesData] = useState({
        options: {},
        series: [],
    });
    const [customPeriod, setCustomPeriod] = useState({ startDate: '', endDate: '' });

    const getLastMonths = (n) => {
        const dates = [];
        const today = new Date();
        for (let i = n - 1; i >= 0; i--) {
            const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
            dates.push(date);
        }
        return dates;
    };

    const calculateSalesForMonth = (transactions, date) => {
        const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
        const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        return transactions
            .filter((transaction) => {
                const transactionDate = new Date(transaction.timestamp);
                return (
                    transactionDate >= startOfMonth &&
                    transactionDate <= endOfMonth &&
                    transaction.status === 'successful'
                );
            })
            .reduce((total, transaction) => total + transaction.amount, 0);
    };

    const calculateCustomSales = (transactions, customPeriod) => {
        if (!customPeriod.startDate || !customPeriod.endDate) return 0;

        const startDate = new Date(customPeriod.startDate);
        const endDate = new Date(customPeriod.endDate);
        return transactions
            .filter((transaction) => {
                const date = new Date(transaction.timestamp);
                return date >= startDate && date <= endDate && transaction.status === 'successful';
            })
            .reduce((total, transaction) => total + transaction.amount, 0);
    };

    useEffect(() => {
        const lastThreeMonths = getLastMonths(3);
        const monthlySales = lastThreeMonths.map((date) =>
            calculateSalesForMonth(transactions, date)
        );
        const customSales = calculateCustomSales(transactions, customPeriod);
        const formatDate = (dateString) => {
          const date = new Date(dateString);
          const day = String(date.getDate()).padStart(2, '0');
          const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
          const year = date.getFullYear();
          return `${day}.${month}.${year}`;
      };
        const customPeriodLabel = customPeriod.startDate && customPeriod.endDate
        ? `Între ${formatDate(customPeriod.startDate)} și ${formatDate(customPeriod.endDate)}`
        : 'Custom';
    

    
        setSalesData({
          options: {
              chart: {
                  height: 350,
                  type: 'bar',
                  toolbar: { show: false },
              },
              dataLabels: { enabled: false },
              stroke: { show: true, width: 2, colors: ['transparent'] },
              xaxis: {
                  categories: [
                      ...lastThreeMonths.map(
                          (date) => date.toLocaleString('default', { month: 'long' })
                      ),
                      customPeriodLabel,
                  ],
              },
              yaxis: { title: { text: 'MDL' }, decimalsInFloat: 2 },
              plotOptions: {
                bar: {
                    distributed: true, // this line is mandatory
                    horizontal: false,
                    barHeight: '85%',
                },
            },
            colors: [ // this array contains different color code for each data
            "#A5978B",
            "#2b908f",
            "#f9a3a4",
            "#90ee7e",],
              legend: { show: true },
              title: {
                  text: 'Monthly Sales Overview',
                  align: 'left',
              },
          },
          series: [
              {
                  name: 'Sales',
                  data: [...monthlySales, customSales],
              },
          ],
      });
  }, [transactions, customPeriod]);

    const handleCustomPeriodChange = (field, value) => {
        setCustomPeriod((prev) => ({ ...prev, [field]: value }));
    };

    return (
        <Fragment>
            <Col>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col xs="9">
                                <H5>Incasari Lunare</H5>
                            </Col>
                            <Col xs="3" className="text-end">
                                <Navigation className="text-muted" />
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row className="mb-3">
                            <Col md="4">
                                <Input
                                    type="date"
                                    value={customPeriod.startDate}
                                    onChange={(e) =>
                                        handleCustomPeriodChange('startDate', e.target.value)
                                    }
                                />
                            </Col>
                            <Col md="4">
                                <Input
                                    type="date"
                                    value={customPeriod.endDate}
                                    onChange={(e) =>
                                        handleCustomPeriodChange('endDate', e.target.value)
                                    }
                                />
                            </Col>
                        </Row>
                        <div className="chart-container">
                            <Charts
                                options={salesData.options}
                                series={salesData.series}
                                height="380"
                                type="bar"
                            />
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Fragment>
    );
};

export default SalesChart;
