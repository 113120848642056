import { useState, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import authService from '../Services/AuthService';
import CustomizerContext from '../_helper/Customizer';

function useLogin() {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { layoutURL } = useContext(CustomizerContext);
    const { setAuthUser, setIsLoggedIn} = useAuth(); 

    const login = useCallback(async (email, password) => {
        setLoading(true);
        try {
            const data = await authService.login(email, password);
            setAuthUser(data.userDTO);
            setIsLoggedIn(true);
            navigate(`${process.env.PUBLIC_URL}/pages/general/${layoutURL}`);
            return data;
        } catch (error) {
            console.error("failed to login: " + error.response);
        } finally {
            setLoading(false);
        }
    }, [navigate, setAuthUser, setIsLoggedIn]);

    return { login, loading };
}

export default useLogin;
